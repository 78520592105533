import "react-app-polyfill/ie9";
import React from "react";
import "./index.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./components/App";
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(<App />);


