import React, { useEffect } from "react";
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import AppBody from "./AppBody"

const App = () => {

  useEffect(() => {
    if(window.innerWidth < 900){
      window.location.replace('https://vaafm.maps.arcgis.com/apps/webappviewer/index.html?id=71dd1d4d4f6745a58b6d39a0480cf4b2')
    }
  }, [])


  return (
    <div className="App">
      <PublicHeader />
<AppBody />
      <PublicFooter />
    </div>
  );
};

export default App;
