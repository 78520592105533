let base_URL;
if (process.env.NODE_ENV !== "production") {
  base_URL = "http://localhost:3000";
}  
else if (window.location.href.toLowerCase().search("dev.accdmaps.vermont.gov") !== -1) {
  base_URL = "http://dev.accdmaps.vermont.gov/MunicipalPlanningDataCenter/"
}
else if (window.location.href.toLowerCase().search("accdmaps.vermont.gov/") !== -1) {
  base_URL = "http://accdmaps.vermont.gov/MunicipalPlanningDataCenter/"
}
else {
  base_URL = "";
}

let basename;
if (process.env.NODE_ENV !== "production") {
  basename = "/MunicipalPlanningDataCenter/";
} 
else if (window.location.href.toLowerCase().search("dev.accdmaps.vermont.gov") !== -1) {
  basename = "/MunicipalPlanningDataCenter/"
}
else if (window.location.href.toLowerCase().search("accdmaps.vermont.gov/MunicipalPlanningDataCenter") !== -1) {
  basename = "/MunicipalPlanningDataCenter/"
}
else {
  basename = "/MunicipalPlanningDataCenter/";
}

module.exports = { base_URL, basename };
