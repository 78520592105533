import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "../css/PublicFooter.css"

const year = new Date().getFullYear();

class Footer extends Component {
  render() {
    return (
      <span className="footer">
        <Container className="bottom-bar-outer-container">
          <Container className="bottom-bar-inner-container">
            <Row className="bottom-row align-items-center">
              <Col>
                <span className="bottom-copyright">
                  <a
                    rel="noopener noreferrer"
                    alt="Accessibility Policy"
                    href="http://vermont.gov/portal/"
                    target="_blank"
                    className="bottom-row-text bottom-row-link"
                  >
                    Copyright © {year}
                  </a>
                  {`  `}
                  <span className="bottom-row-text">
                    State of Vermont. All rights reserved.{" "}
                  </span>
                  {`  `}
                </span>
                <span className="bottom-policies">
                  {`  `}
                  <a
                    rel="noopener noreferrer"
                    alt="Accessibility Policy"
                    href="http://www.vermont.gov/portal/policies/accessibility.php"
                    target="_blank"
                    className="bottom-row-text bottom-row-link"
                  >
                    Accessibility Policy
                  </a>
                  {`  `}
                  <span className="bottom-row-text">+</span>
                  {` `}
                  <a
                    rel="noopener noreferrer"
                    alt="Privacy Policy"
                    href="http://www.vermont.gov/portal/policies/privacy.php"
                    target="_blank"
                    className="bottom-row-text bottom-row-link"
                  >
                    Privacy Policy
                  </a>
                  .
                </span>
              </Col>
            </Row>
          </Container>
        </Container>
      </span>
    );
  }
}

export default Footer;
