import React from "react";
import "../css/AppBody.css";

const AppBody = (props) => {

  return (

      <div className="body-content">
        <iframe src="https://vaafm.maps.arcgis.com/apps/webappviewer/index.html?id=71dd1d4d4f6745a58b6d39a0480cf4b2" height="100%" width="100%" allow="geolocation *;" title="creemee-locator" frameBorder="0"/>  

      </div>
  );
};

export default AppBody;
